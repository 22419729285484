<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outerOne">
      <div>
        <img src="@/assets/img/dsBJ2.png" alt="" />
        <div>
          <div class="oneTitle">
            {{ $t("lithographySection.LS_oneTitle") }}
          </div>
          <div class="oneCont">
            {{ $t("lithographySection.LS_oneCont") }}
          </div>
        </div>
      </div>
    </div>

    <div class="outerTwo">
      <img src="@/assets/img/lsImg1.png" alt="" />
    </div>

    <div class="outerThree">
      <div class="threeTip">
        {{ $t("lithographySection.LS_threeTip") }}
      </div>
      <div class="threeModular">
        <div
          class="threeCont"
          v-for="(item, index) in $t('lithographySection.LS_threeModular')"
          :key="index"
        >
          <img :src="item.img" alt="" />
          <div v-html="item.name"></div>
        </div>
      </div>
    </div>

    <div class="outerFour">
      <div class="fourTip fourTipOne">
        {{ $t("lithographySection.LS_fourTipOne") }}
      </div>
      <div class="fourCont fourContOne">
        <div class="foutTitle">Design</div>
        <div class="contOne">
          <div
            v-for="(item, index) in $t('lithographySection.LS_fourContOne')"
            :key="index"
            class="contItem"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="jiantou fourTipTwo">
        <img src="@/assets/img/lsImgjiantou.png" alt="" />
      </div>
      <div class="fourCont fourContTwo">
        <div class="foutTitle">
          PET
          <div>（Patterning Enhancement Technology）</div>
        </div>
        <div class="contOuter">
          <div class="contList">
            <div class="contTitle">DFM</div>
            <div class="contOne contTwo">
              <div
                v-for="(item, index) in $t('lithographySection.LS_fourContTwo')"
                :key="index"
                class="contItem"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="contList">
            <div class="contTitle">OPC</div>
            <div class="contOne contTwo">
              <div
                v-for="(item, index) in $t(
                  'lithographySection.LS_fourContThree'
                )"
                :key="index"
                class="contItem"
              >
                {{ item }}
              </div>
            </div>
            <div class="contTitle contTitleTwo">
              {{ $t("lithographySection.LS_fourTipTwo") }}
            </div>
            <div class="contThree">
              <img src="@/assets/img/lsFourImg1.png" alt="" />
              <!-- <img src="@/assets/img/lsFourImg2.png" alt="" /> -->
            </div>
          </div>
          <div class="contList">
            <div class="contTitle">MTO</div>
            <div class="contOne contTwo">
              <div
                v-for="(item, index) in $t(
                  'lithographySection.LS_fourContFour'
                )"
                :key="index"
                class="contItem"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fourCont fourContThree">
        <div class="foutTitle">
          {{ $t("lithographySection.LS_fourTipThree") }}
        </div>
        <div class="contFour">
          <img src="@/assets/img/lsFourImg3.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：先进光刻技术研发部
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-10-08 10:04
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "技术研发",
          title_en: "Technology R & D",
          path: "TechnologyRD",
        },
        {
          title_zh: "部门设置",
          title_en: "Department settings",
          path: "departmentSettings",
        },
        {
          title_zh: "光刻部",
          title_en: "Lithography section",
          path: "",
        },
      ],
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollbox, true);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollbox, true);
    this.handleScrollboxNow();
  },
  methods: {
    /**
     * 动画效果
     * 刘嘉鑫
     * 2022-9-29
     */
    handleScrollboxNow() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      if (this.currentScroll >= this.$(".outerOne").offset().top - 950) {
        this.$(".outerOne>div").addClass("showDiv");
      }
    },
    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置

      if (this.currentScroll >= this.$(".threeTip").offset().top - 900) {
        this.$(".threeTip").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourTipOne").offset().top - 600) {
        this.$(".fourTipOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourTipTwo").offset().top - 580) {
        this.$(".fourTipTwo").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourContOne").offset().top - 580) {
        this.$(".fourContOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourContTwo").offset().top - 580) {
        this.$(".fourContTwo").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourContThree").offset().top - 580) {
        this.$(".fourContThree").addClass("showDiv");
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.appCont {
  background: #f6f6f6;
}
.outerOne {
  padding: 80px 310px 40px;
  > div {
    display: flex;
    align-items: flex-start;
    transition: all 0.7s ease-in 0s;
    transform: translateY(20px);
    opacity: 0;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 30px;
    }

    div {
      .oneTitle {
        font-size: 28px;
        font-weight: bold;
        color: #222222;
        margin-bottom: 30px;
      }
      .oneCont {
        font-size: 18px;
        font-weight: 400;
        color: #222222;
        line-height: 32px;
      }
    }
  }
}

.outerTwo {
  background: #1a2a60;
  padding: 25px 461px;

  img {
    width: 90%;
    // height: 539px;
  }
}

.outerThree {
  .threeTip {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #222222;
    padding: 80px 310px 60px;
    transition: all 0.7s ease-in 0s;
    transform: translateY(20px);
    opacity: 0;
  }

  .threeModular {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .threeCont {
      width: 33.33333%;
      height: 440px;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        transition: all 0.9s !important;
      }
      div {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        line-height: 45px;
        text-align: center;
      }
    }

    .threeCont:hover {
      > img {
        transform: scale(1.2) !important;
      }
    }
  }
}
.outerFour {
  padding: 0 258px 45px;

  .fourTipOne,
  .fourContOne,
  .fourTipTwo,
  .fourContTwo,
  .fourContThree {
    transition: all 0.6s ease-in 0s;
    transform: translateY(30px);
    opacity: 0;
  }

  .fourTip {
    font-size: 28px;
    font-weight: bold;
    color: #222222;
    padding: 80px 50px 40px;
  }

  .fourContThree {
    margin-top: 30px;
  }

  .fourCont {
    .foutTitle {
      background: #1a2a60;
      padding: 17px 0;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      > div {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        display: inline;
      }
    }

    .contOuter {
      background: #ffffff;
      padding: 30px 40px;
    }

    .contList {
      border: 1px dashed #1a2a60;
      padding: 20px 0 0;
      margin-bottom: 30px;
    }

    .contList:last-child {
      margin-bottom: 0 !important;
    }

    .contTitle {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #1a2a60;
      margin-bottom: 20px;
      text-align: center;
    }

    .contTitleTwo {
      margin-top: -12px;
      margin-bottom: 30px;
    }

    .contOne {
      background: #ffffff;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 30px 40px 0;
    }

    .contTwo {
      padding: 0 30px 0 !important;
    }

    .contTwo .contItem {
      width: 19.8%;
    }

    .contItem {
      background: #f6f6f6;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #222222;
      padding: 18px 11px;
      text-align: center;
      width: 20%;
      margin: 0 58px 30px 0;
    }

    .contItem:nth-child(4n) {
      margin-right: 0 !important;
    }

    .contThree {
      padding: 0 86px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 95%;
        // height: 225px;
        object-fit: inherit !important;
        margin: 0 auto;
      }
    }

    .contFour {
      background: #ffffff;
      padding: 30px 150px;
      img {
        width: 80%;
        height: 100%;
        margin: 0 auto;
      }
    }
  }

  .jiantou {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    img {
      width: 20px;
      height: 28px;
    }
  }
}
</style>