var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('currentLocation',{attrs:{"sibItem":_vm.sibItem}}),_c('div',{staticClass:"outerOne"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/dsBJ2.png"),"alt":""}}),_c('div',[_c('div',{staticClass:"oneTitle"},[_vm._v(" "+_vm._s(_vm.$t("lithographySection.LS_oneTitle"))+" ")]),_c('div',{staticClass:"oneCont"},[_vm._v(" "+_vm._s(_vm.$t("lithographySection.LS_oneCont"))+" ")])])])]),_vm._m(0),_c('div',{staticClass:"outerThree"},[_c('div',{staticClass:"threeTip"},[_vm._v(" "+_vm._s(_vm.$t("lithographySection.LS_threeTip"))+" ")]),_c('div',{staticClass:"threeModular"},_vm._l((_vm.$t('lithographySection.LS_threeModular')),function(item,index){return _c('div',{key:index,staticClass:"threeCont"},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('div',{domProps:{"innerHTML":_vm._s(item.name)}})])}),0)]),_c('div',{staticClass:"outerFour"},[_c('div',{staticClass:"fourTip fourTipOne"},[_vm._v(" "+_vm._s(_vm.$t("lithographySection.LS_fourTipOne"))+" ")]),_c('div',{staticClass:"fourCont fourContOne"},[_c('div',{staticClass:"foutTitle"},[_vm._v("Design")]),_c('div',{staticClass:"contOne"},_vm._l((_vm.$t('lithographySection.LS_fourContOne')),function(item,index){return _c('div',{key:index,staticClass:"contItem"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_vm._m(1),_c('div',{staticClass:"fourCont fourContTwo"},[_vm._m(2),_c('div',{staticClass:"contOuter"},[_c('div',{staticClass:"contList"},[_c('div',{staticClass:"contTitle"},[_vm._v("DFM")]),_c('div',{staticClass:"contOne contTwo"},_vm._l((_vm.$t('lithographySection.LS_fourContTwo')),function(item,index){return _c('div',{key:index,staticClass:"contItem"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_c('div',{staticClass:"contList"},[_c('div',{staticClass:"contTitle"},[_vm._v("OPC")]),_c('div',{staticClass:"contOne contTwo"},_vm._l((_vm.$t(
                'lithographySection.LS_fourContThree'
              )),function(item,index){return _c('div',{key:index,staticClass:"contItem"},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"contTitle contTitleTwo"},[_vm._v(" "+_vm._s(_vm.$t("lithographySection.LS_fourTipTwo"))+" ")]),_vm._m(3)]),_c('div',{staticClass:"contList"},[_c('div',{staticClass:"contTitle"},[_vm._v("MTO")]),_c('div',{staticClass:"contOne contTwo"},_vm._l((_vm.$t(
                'lithographySection.LS_fourContFour'
              )),function(item,index){return _c('div',{key:index,staticClass:"contItem"},[_vm._v(" "+_vm._s(item)+" ")])}),0)])])]),_c('div',{staticClass:"fourCont fourContThree"},[_c('div',{staticClass:"foutTitle"},[_vm._v(" "+_vm._s(_vm.$t("lithographySection.LS_fourTipThree"))+" ")]),_vm._m(4)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outerTwo"},[_c('img',{attrs:{"src":require("@/assets/img/lsImg1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jiantou fourTipTwo"},[_c('img',{attrs:{"src":require("@/assets/img/lsImgjiantou.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foutTitle"},[_vm._v(" PET "),_c('div',[_vm._v("（Patterning Enhancement Technology）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contThree"},[_c('img',{attrs:{"src":require("@/assets/img/lsFourImg1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contFour"},[_c('img',{attrs:{"src":require("@/assets/img/lsFourImg3.png"),"alt":""}})])
}]

export { render, staticRenderFns }